.theme-search-bar{
  .global-alt-link{
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 10px;
    transition: color $transition-standard;
    &:hover{
      text-decoration: none;
      color: #FFF;
    }
  }

  .global-social-links{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
  .sn-social-media-icon{
    margin: 0;
    padding: 0 10px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: auto;
    height: 100%;
    line-height: 1;
    font-size: 20px;
    color: $primary-blue;
    transition: color $transition-standard;
    &:before{
      font-size: inherit;
      line-height: auto;
      width: auto;
      left: auto;
    }
    &:hover{
      color: #FFF;
    }
  }
  .sn-social-media-list:not(.light):not(.dark) .sn-social-media-icon:hover{
    background-color: transparent;
  }
  .sn-social-media-icon[href^="https://www.snapchat.com/"]:before{
    content: "\f2ac";
  }
}


.home{
  #siteContainer{
    padding-top:0;
  }
}
.textBlockElement{
  > h3:only-child{
    margin-bottom: 0;
  }
}

.green-stripe{
  box-shadow: inset 0px 70px $primary-green;
}
.collapse-outer{
  .column{
    &:first-of-type{padding-left:0;}
    &:last-of-type{padding-right:0;}
  }
}
.primary-blue{
  background-color: $primary-blue;
}
.element-header-default .textBlockElement > h3 {
  font-family: $fontOne;
  font-weight: 800;
}
.primary-green-left{

}
.primary-green{
  &-background{background-color: $primary-green;}
  &-left{
    &:before{
      content: "";
      display: block;
      position: absolute;
      background-color: $primary-green;
      top: 0;
      bottom: 0;
      width: calc((100vw - #{$maxWidth}) / 2);
      right: calc(( 100vw + #{$maxWidth} ) / 2);
      .page-manager-visible &{
        right: calc(( 100vw + #{$maxWidth} - 60px ) / 2);
      }
    }
    .column-1{
      background-color: $primary-green;
    }
  }
}
.tagboard-background{
  background-image: url(../images/tagboard-background.jpg);
  background-color: $primary-blue;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.cta-banner{
  .heroPhotoElement{
    position: relative;
    padding-top: 80px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    min-height: 168px;
    &:before{
      content: "";
      display: block;
      position: absolute;
      top:0; left:0; right:0; bottom:0;
      width: 100%;
      background: linear-gradient(to top, rgba($primary-blue, 1) 0%, rgba($primary-blue, 0) 50%);
      z-index: 0;
    }
  }
  br, img{ display: none;}
  a{display: inline-block;}
  a:first-of-type{
    position: absolute;
    top:0; left:0; right:0; bottom:0;
  }
  .cutline{
    display: inline-block;
    border-bottom: 20px solid $primary-green;
    padding: 10px 20px;
    background: none;
    text-align: left;
    text-transform: uppercase;
    color: #FFF;
    width: auto;
    font-family: $fontOne;
    font-size:32px;
    font-weight: 800;
    position: relative;
  }
}



// 634 x 300 = 47.318%   61199

.cta-2-1{
  .heroPhotoElement{
    position: relative;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: flex-end;
    br{ display: none;}
    img{
      opacity: 0;
      width: auto;
      height: 0;
      padding-bottom: 47.318%;
      float: left;
    }
    .cutline{
      text-align: left;
      width: auto;
      display: inline-block;
      color: #FFF;
      background-color: rgba($primary-blue, .85);
      border-bottom: 5px solid $primary-green;
      font-size: 18px;
      font-weight: 500;
    }
    .cutUrl{
      order: -1;
    }
  }
}
