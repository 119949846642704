// // Responsive Image Cover
// .sn-media-slider .media-wrapper{
//   background-size: cover;
//   background-position: center center;
//   background-repeat: no-repeat;
// }
// .sn-media-slider .slides .slide .media-wrapper img{
//   display: none !important;
// }
//
//
// // Remove default unwanted styling
// .mediaSlider{
//   > h2, > p{
//     &:empty{display: none;}
//   }
// }
// .sn-media-slider .no-paging.top-left-paging,
// .sn-media-slider .no-paging.top-center-paging,
// .sn-media-slider .no-paging.top-right-paging{
//   margin-top: 0;
// }
// .sn-media-slider .no-paging.bottom-left-paging,
// .sn-media-slider .no-paging.bottom-center-paging,
// .sn-media-slider .no-paging.bottom-right-paging{
//   margin-bottom: 0;
// }
//
//
//
// // All media sliders
// .sn-media-slider{
//   .slider-pagination{
//     height: auto;
//     display: flex;
//     flex-flow: row wrap;
//     justify-content: center;
//     .paging-item{
//       height: 14px;
//       width: 14px;
//       background-color: $primary-blue;
//       margin: 5px 10px;
//       transition: background-color .2s ease;
//       &:hover,
//       &.flex-active{
//         background-color: $primary-green;
//       }
//     }
//   }
// }
//
//
// .custom-media-slider{
//   .sn-media-slider{
//     .slider{
//       @media screen and (max-width: 1720px){
//         max-height: 430px;
//         min-height: 430px;
//       }
//       @media screen and (max-width: 299px){
//         max-height: 350px;
//         min-height: 350px;
//       }
//     }
//     .slide{
//       > a{
//         height: 100%;
//         display: flex;
//         flex-flow: column;
//         justify-content: flex-end;
//         align-items: center;
//         text-decoration: none;
//         &:hover{text-decoration: none; }
//       }
//       .media-wrapper{
//         z-index: -1;
//         top: 0;
//         left: 0;
//         bottom: 0;
//         right: 0;
//       }
//     }
//     .slides .slide-overlay{
//       position: relative;
//       height: auto;
//       max-width: $maxWidth;
//       width: 100%;
//       margin: 0 auto;
//       padding: 0 5%;
//     }
//
//     .slide-text{
//       background-color: #FFF;
//       background-image: linear-gradient(to top, #FFF 0, rgba(#FFF, 0) 75%), url(../images/dot-pattern.svg);
//       background-size: 100%, 5px 5px;
//       background-repeat: no-repeat, repeat;
//       padding: 30px 30px 0 30px;
//       &:hover{
//         text-decoration: none;
//       }
//     }
//     .slide-title{
//       color: $primary-blue;
//       font-size: em(48);
//       font-weight: 800;
//       line-height: 1.1;
//       text-transform: uppercase;
//       text-shadow: none;
//       margin: 0 0 20px 0;
//     }
//     .slide-description{
//       margin: 0 0 30px 0;
//       position: relative;
//       background-color: $link-color;
//       display: inline-flex;
//       flex-flow: row;
//       align-items: stretch;
//       color: #fff;
//       font-weight: 500;
//       font-size: em(16);
//       text-transform: uppercase;
//       padding: 8px 0px 8px 15px;
//       line-height: 1.3;
//       text-shadow: none;
//       &:hover{
//         text-decoration: none;
//         &:after{
//           padding: 8px 8px 8px 16px;
//         }
//       }
//       &:after{
//         background-color: $accent-color;
//         color: $primary-blue;
//         content: "\f101"; // Right Arrow
//         font-family: FontAwesome;
//         line-height: .8em;
//         padding: 8px 12px;
//         display:inline-flex;
//         flex-flow: row;
//         align-items: center;
//         margin: -8px 0 -8px 15px;
//         transition: color .2s, padding .2s;
//       }
//     }
//   }
// }

//


$paging-item-size: 6px;
$paging-item-touch: 6px;
$paging-outer-distance: (20px - ($paging-item-size + $paging-item-touch));

.pageEl{
  .flexslider{
    &.slider{ // extra selector for specificity
      margin: 0;
    }
  }
  .media-wrapper{
    box-shadow: inset 0px -70px 100px -35px $primary-blue;
  }
  .slider.bottom-center-paging .slider-pagination,
  .slider.bottom-left-paging .slider-pagination,
  .slider.bottom-rigth-paging .slider-pagination{
    bottom: $paging-outer-distance;
  }
  .slider.top-center-paging .slider-pagination,
  .slider.top-left-paging .slider-pagination,
  .slider.top-rigth-paging .slider-pagination{
    top: $paging-outer-distance;
  }
  .slider-pagination .paging-item{
    // border: 2px solid #FFF;
    background: transparent;
    transition: background $transition-linear;
    &.flex-active, &:hover{
      // border: 2px solid $link-color;
      background: $primary-green;
    }
  }
  .sn-media-slider .slider-pagination .paging-item {
    cursor: pointer;
    height: 10px;
    width: 10px;
    padding: $paging-item-size;
    border: $paging-item-touch solid transparent;
    background-clip: padding-box;

    &:after{
      content: "";
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 2px solid #FFF;
      border-radius: 100%;
      width: 17px;
      height: 17px;
      transition: border $transition-standard;
    }
    &.flex-active, &:hover{
      &:after{
        border-color: transparent;
      }
    }
  }
  .sn-media-slider .slider-pagination{
    height: auto;
    line-height: 1;
  }
  // .paging-item + .paging-item{ margin-left: 14px; }

  // black overlay
  .media-wrapper a:last-child:before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    // background-color: rgba(0,0,0,.1);
    // background-image: linear-gradient(to top, rgba(#000, .75) 0%, rgba(#000, 0) 45%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .mediaSlider{
    > h2, > p{display: none;} // hide elements while js is wrapping them
  }
  .sn-media-slider .slides .slide .slide-overlay,
  .sn-media-slider .slider-pagination{
    max-width: $maxWidth;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 20px;
    box-sizing: border-box;
  }
  .sn-media-slider .slides .slide .slide-overlay{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
  }
  .sn-media-slider .slider .slide-text{
    display: block;
    width: auto;
    height: auto;
    background-color: rgba(#101820, .25);
    padding: 25px 43px;
    max-width: $maxWidth/2;
    @include font-smoothing;

    // .slide-title, .slide-description{
    //   padding: 0;
    // }
  }
}


// .sn-media-slider .slides .slide .media-wrapper img {
//     display: block !important;
// }
.sn-media-slider .slider .slide-text{
  $media-slider-padding: 60px;
  &.top-left{padding-top: $media-slider-padding;}
  &.top-center{padding-top: $media-slider-padding;}
  &.top-right{padding-top: $media-slider-padding;}
  &.middle-left{padding-bottom: ($media-slider-padding / 2); padding-top: ($media-slider-padding / 2);}
  &.middle-center{padding-bottom: ($media-slider-padding / 2); padding-top: ($media-slider-padding / 2);}
  &.middle-right{padding-bottom: ($media-slider-padding / 2); padding-top: ($media-slider-padding / 2);}
  &.bottom-left{padding-bottom: $media-slider-padding;}
  &.bottom-left{padding-bottom: $media-slider-padding;}
  &.bottom-left{padding-bottom: $media-slider-padding;}
}
.sn-media-slider .slider .slide-title{
  font-family: $fontOne;
  color: #FFF;
  font-size: em(40);
  @media screen and(max-width:900px){font-size: em(36);}
  @media screen and(max-width:700px){font-size: em(32);}
  @media screen and(max-width:500px){font-size: em(30);}
  @media screen and(max-width:300px){font-size: em(28);}
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;
  margin: 0 0 0 0;
  padding: 0 0 0 45px;
  box-shadow: inset 35px 0px $primary-green;
  text-shadow: none;
  &:empty{display:none;}
}
.sn-media-slider .slider .slide-description{
  font-family: $fontTwo;
  font-size: em(16);
  @media screen and(max-width:300px){font-size: em(24);}
  font-weight: 700;
  line-height: 1.4;
  text-transform: none;
  margin: 10px 0 0 0;
  padding: 0;
  text-shadow: none;
  &:empty{display:none;}
}
.edit_mode .sn-media-slider {
    margin-top: 0;
    margin-bottom: 0;
}


.media-wrapper{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.sn-media-slider .slides .slide .media-wrapper img{
  display: none !important;
}

@media screen and (max-width: 700px){
  .layout-100{
    .sn-media-slider .flexslider{
      min-height: 300px;
    }
  }
}


.custom-media-slider{
  .sn-media-slider .flexslider{
    max-height: 400px;
  }
}


//




.custom-ratio{
  .sn-media-slider{
    .slider{
      .page-manager-visible &{height: calc(25.50vw - 60px) !important;}
      height: 25.50vw !important;
      .slide-overlay{
        padding: 10px;
      }
      .slide-description,
      .slide-title{
        margin: 5px auto;
        max-width: $maxWidth - 60px;
      }
    }
  }
}


@media screen and (max-width: 450px){
  .custom-media-slider{
    .sn-media-slider .flexslider {
      min-height: auto;
      max-height: initial;
      height: auto !important;
    }
    .slides{
      position: relative;
    }
    .slide{
      position: relative;
      display: flex !important;
      flex-flow: column-reverse;
    }
    .sn-media-slider .slides .slide .slide-overlay{
      position: relative;
      background-color: #002b49;
      max-width: initial;
      left: auto;
      transform: none;
      padding:0;
    }
    .slides .slide .media-wrapper{
      position: relative;
      img{
        display: block !important;
        position: relative !important;
        top: 0 !important;
      }
    }

    .sn-media-slider .slider .slide-text{
      background: transparent;
      padding: 20px 20px 50px 20px;
    }

  }
}
