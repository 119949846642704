// Site Footer
.snFooterContainer{
  background-color: #FFF;
}
#siteFooter {
  background-color: $primary-blue;
  > ul {
    max-width: $maxWidth;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 0;
    width: 100%;
    padding: 90px 0 10px 0;
    color: #FFF;
    font-size: 14px;
    li {
      display: inline-block;
      padding: 0 .2em;
      a {
        display: inline-block;
        color: #FFF;
        border-bottom: 1px solid transparent;
        transition: $transition-standard;
        &:hover {
          text-decoration: none;
          border-bottom: 1px solid #FFF;
        }
      }
    }

  }
}
#siteFooter li#poweredByNGIN{
	a{
		display:inline-block;
		width:120px;
		height:20px;
		position:relative;
    border: none;
		&:after{
			content:url($asset-path-for+'/logo_images/logo.svg'); // /logo_images/logo.svg") right center no-repeat
			display:block;
			position:absolute;
			top:8px;
			left:0;
			width:100%;
		}
		img{
			display:none;
		}
    &:hover{
      border: none;
    }
	}
}


//
#footer-content{
  // General layout
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  max-width: $maxWidth + ($horizontal-rythm * 2);
  width: 100%;
  margin: 0 auto;
  padding-top: 45px;

  > div{
    padding: 0 $horizontal-rythm;
    width: 25%;
    flex-basis: 25%;
    @media screen and (max-width: 1000px){
      width: 50%;
      flex-basis: 50%;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 450px){
      width: 100%;
      flex-basis: 100%;
      max-width: 244px;
      margin: 0 auto;
    }

  }
  @media screen and (max-width: 450px){
    .quick-links,
    .visit-us,
    .g-map{ display: none; }
  }

  // Social Media
  .global-social-links{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
  }
  .sn-social-media-icon{
    margin: 0;
    padding: 0 5px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: auto;
    height: 100%;
    line-height: 1;
    font-size: 20px;
    color: $primary-green;
    transition: color $transition-standard;
    &:before{
      font-size: inherit;
      line-height: auto;
      width: auto;
      left: auto;
      border: 2px solid $primary-green;
      border-radius: 100%;
      width: 35px;
      line-height: 35px;
      font-size: 23px;
      transition: border $transition-standard;
    }
    &:hover{
      color: $primary-blue;
      &:before{
        border: 2px solid $primary-blue;
      }
    }
  }
  .sn-social-media-list:not(.light):not(.dark) .sn-social-media-icon:hover{
    background-color: transparent;
  }
  .sn-social-media-icon[href^="https://www.snapchat.com/"]:before{
    content: "\f2ac";
  }

  // Links
  .footer-link{
    h4{
      font-family: $fontTwo;
      font-size: 16px;
      text-transform: uppercase;
      a{
        background: none;
        box-shadow: none;
        background-color: $grey-llt;
        color:$primary-blue;
        text-align: center;
        width: 100%;
        padding: 20px 5px;
      }
    }
    .goToLink{
      &:before, &:after{ display: none; }
    }
    + .footer-link{
      margin-top: 12px;
    }
  }

  // Text Blocks
  .quick-links,
  .visit-us{
    .textBlockElement > h3{
      background-color: $primary-blue;
      color: #FFF;
      font-family: $fontOne;
      font-size: 18px;
      font-weight: 700;
      text-transform: none;
      padding: 5px 10px;
      line-height: 1.2;
    }
  }

  // Map
  .g-map{
    .codeElement{
      overflow:hidden;
      padding-bottom: 70%;
      position:relative;
      height:0;
      iframe{
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;

      }
    }
  }

}
#footer-graphics{
  padding-top: 310px;
  background-image:
    url(../images/grass-tile.png),
    url(../images/players-left.svg),
    url(../images/players-right.svg),
    url(../images/lights-left.svg),
    url(../images/lights-right.svg),
    url(../images/trees-left.svg),
    url(../images/trees-right.svg);
  background-repeat:
    repeat-x,
    no-repeat,
    no-repeat,
    no-repeat,
    no-repeat,
    no-repeat,
    no-repeat;
  background-size:
    36.58px 15px,
    315px 156px,
    250px 148px,
    102px 230px,
    102px 230px,
    362px 290px,
    384px 290px;
  background-position:
    left bottom,
    right 70% bottom,
    left 70% bottom,
    left 20% bottom,
    right 20% bottom,
    left -3% bottom,
    right -3% bottom;
  @media screen and (max-width: 1450px){
    background-position:
      left bottom,
      right 70% bottom,
      left 70% bottom,
      left 20% bottom,
      right 20% bottom,
      left -180px bottom,
      right -180px bottom;
  }
  @media screen and (max-width: 1200px){
    background-position:
      left bottom,
      right 80% bottom,
      left 80% bottom,
      left 15% bottom,
      right 15% bottom,
      left -200px bottom,
      right -200px bottom;
  }
  @media screen and (max-width: 1000px){
    background: none;
    background-image: url(../images/footer-graphic.png);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding-top: 15%;
  }
  @media screen and (max-width: 450px){
    padding-top: 25%;
  }
}
