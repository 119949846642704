// Sponsor Bar
$sponsor-selector: sponsor-slider; // replace with the class used on the ajax target layout container

$sponsor-content-background-color: $primary-blue; //#FFF;
$sponsor-content-height: 130px;

$sponsor-container-horz-padding: 0px;
$sponsor-container-height: ($sponsor-container-horz-padding * 2) + $sponsor-content-height;
$sponsor-container-background-color: transparent;

$sponsor-nav-font: 'FontAwesome';
$sponsor-nav-width: 45px;
$sponsor-nav-background-color: $primary-blue; //#FFF;
$sponsor-nav-border-size: 1px;
$sponsor-nav-border-color: transparent;
$sponsor-nav-arrow-color: #FFF;
$sponsor-nav-arrow-color-hover: $grey-llt;

$sponsor-item-container-width: 300px; // set to auto to scroll by each items width;
$sponsor-item-container-background-hover-color: transparent;
$sponsor-item-border-color: transparent;
$sponsor-item-border-size: 1px;
$sponsor-item-vert-padding: 15px;
$sponsor-item-horz-padding: 20px;


.user_mode {

  .#{$sponsor-selector} {
    .column-1 {
      width: 100%;
      height: $sponsor-content-height;
      overflow: hidden;
      white-space: nowrap;
      box-sizing: border-box;
      font-size: 0;
      padding: 0;
      background-color: $sponsor-content-background-color;
     }
  }

  .layoutContainer {
    &.layout-100.#{$sponsor-selector}:not(.full-width, .full-width-container) {

      position: relative;
      width: 100%;
      max-width: $maxWidth;
      padding: $sponsor-container-horz-padding 0;
      // margin: 10px auto;
      background-color: $sponsor-container-background-color;
    }
    &.#{$sponsor-selector} .pageEl {
      display: inline-flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      width: $sponsor-item-container-width;
      padding: 0 $sponsor-item-horz-padding;
      height: $sponsor-content-height;
      border-right: solid $sponsor-item-border-size $sponsor-item-border-color;
      transition: $transition-standard;
      &:hover {
        background: $sponsor-item-container-background-hover-color;
      }
      .pageElement.heroPhotoElement {
        overflow: hidden;
        margin: 0;
        padding: 0;
        img {
          border: none;
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: $sponsor-content-height - ($sponsor-item-vert-padding * 2);
        }
      }
    }
  }
}

.#{$sponsor-selector} .column-1  {
    text-align: center;
}

#sponsorScrollerGroup {
    position: relative;
    left: 0;
    margin: 0;
    padding: 0 $sponsor-nav-width;
    height: $sponsor-content-height;
}
#sponsorScrollerPrev,
#sponsorScrollerNext {
    width: $sponsor-nav-width;
    height: $sponsor-content-height;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    background-color: $sponsor-nav-background-color;
    &:before, &:after{
      color: $sponsor-nav-arrow-color;
      display: block;
      font-family: $sponsor-nav-font;
      font-size: $sponsor-nav-width;
      line-height: 1;
      font-weight: normal;
      transition: color $transition-standard;
    }
    &:hover {
      &:before, &:after{
        color: $sponsor-nav-arrow-color-hover;
      }
    }
}
#sponsorScrollerPrev{
  &:before{ content:"\f104"; }
}
#sponsorScrollerNext {
  &:before{ content:"\f105"; }
    left: auto;
    right: 0;
}

.edit_mode #sponsorBarContainer { display: none; }
#sponsorBarContainer:empty{
  display: none;
}

/* Scales down logos in edit mode so they're easier to manage */

.edit_mode .layoutContainer.#{$sponsor-selector} div.drag {
    display: inline-block;
    width: 33%;
}
.edit_mode{
	.#{$sponsor-selector}{
		.heroPhotoElement{
			min-height: 200px;
			img{
				max-height: 200px;
			}
		}
	}
}
