.tabbedElement{
  .contentTabs{
    margin: 0;
    li{
      border: none;
      border-left: 5px solid $grey-llt;
      transition: border $transition-standard;
      &.selected,
      &:hover{
        border: none;
        border-left: 5px solid $primary-green;
        color: $primary-blue;
        a{
          color: $primary-blue;
        }
      }
      span{
        a{
          font-family: $fontTwo;
          font-size: 16px;
          font-weight: 700;
          padding: 2px 10px;
          color: $grey-lt;
          transition: color $transition-standard;
          text-align: left;
        }
      }
    }
  }
  .tabContainer{
    border: 1px solid $grey-llt;
    margin-top: $vertical-rythm;
    .layoutContainer{
      padding-bottom: 0;
    }
    .pageElement{
      margin: $vertical-rythm 0;
    }
  }
}


// @include page-element-break(400){
//   .tabbedElement{
//     .contentTabs{
//
//     }
//   }
//
// }
