// Utilities
$sn-pageManager-width: 60px;
%standard-width{
  max-width: $maxWidth;
  width: 100%;
  margin: 0 auto;
}

%full-width{
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .page-manager-visible & {
      width: calc(100vw - #{$sn-pageManager-width});
      // margin-left: -30px;
  }
}

%full-width-image-background{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-width{
  .user_mode &{@extend %full-width;}
  // @extend %full-width;
  // margin-right: 0;
  &.layout-100{
    padding-left: 0;
    .column{ padding-left: 0; padding-right: 0;}
  }
}
.full-width-container{
  .user_mode &{@extend %full-width;}
  // @extend %full-width;
  // @extend %full-width-image-background;
  // margin-right: 0;
  .fw-content{
    @extend %standard-width;
    // padding: 0 $content-container-padding;
  }

  // &.oneColumn{
  //   .pageElement{
  //     margin-right: 0;
  //   }
  // }
}
.background-image{
  .user_mode &{ display: none; }
  .edit_mode &{
    &:before{
      max-width: 300px;
      margin: 0 auto;
      display: block;
      padding: 1em;
      text-align: center;
      content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
    }
    img{
      max-width: 300px;
      margin: 0 auto;
      border: 2px solid #FFF;
      box-shadow: 0px 0px 15px rgba(0,0,0,.5);
    }
  }
}
.background-cover-img{
  @extend %full-width-image-background;
}


.compact{
  // &.layoutContainer{padding-left: 0;}
  // .column{padding-left:0;padding-right:0;}
  &.pageEl .pageElement{margin-bottom: 0; margin-top: 0;}
  .pageEl .pageElement{margin-bottom: 0; margin-top: 0;}
  &-top{
    &.pageEl .pageElement{margin-top: 0;}
    .pageEl .pageElement{margin-top: 0;}
  }
  &-bottom{
    &.pageEl .pageElement{margin-bottom: 0;}
    .pageEl .pageElement{margin-bottom: 0;}
  }
}
@media screen and (max-width: 767px){
  .compact:not(.layout-100) .pageEl .pageElement{margin-bottom: 25px;}
  .compact:not(.layout-100) .column:last-of-type .pageEl:last-of-type .pageElement{
    margin-bottom: 0;
  }
}

.layoutContainer{
  &.padding{
  padding-top: 40px;
  padding-bottom: 40px;
  }
  &.padding-top{
    padding-top: 40px;
  }
  &.padding-bottom{
    padding-bottom: 40px;
  }
  // &.compact{
  //   .pageEl{
  //     margin-top: 0;
  //     margin-bottom: 0;
  //   }
  // }
  // &.compact-top{
  //   .pageEl{
  //     margin-top: 0;
  //   }
  // }
  // &.compact-bottom{
  //   .pageEl{
  //     margin-bottom: 0;
  //   }
  // }
}


.center-align{
  .fw-content, &.layoutContainer{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}



.maintain-ratio .video_aspect_ratio {
    padding-bottom: 0;
}

.element-header{
  .textBlockElement > h3 {@include special-header;}
  &.light{
    .textBlockElement > h3 {
      background-color: transparent;
      color: $grey-md;
    }
  }
}

.hashtag{
  .textBlockElement > h3{
    color: $primary-green;
    font-size: 36px;
    font-weight: 800;
    font-family: $fontOne;
    span:before{
      content: "#";
      color:#FFF;
      display: inline-block;
    }
  }
}
