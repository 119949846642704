#sn-site-logo {
  position: absolute;
  top: $site-tagline-height;
  left: 0;
  width: $logo-width-desktop;
  height: $logo-height-desktop;
  padding: 0; //5px 0; //17px 0;
  box-sizing: content-box;
  z-index: 1;
  .has-main-nav & {
    margin-top: -12px;
  }

  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-background-size: contain;
  }
}
