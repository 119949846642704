body#survey_steps { padding: 0; }

html {

  &.has-mobile-nav {

  }
  &.has-main-nav{
    .site-background{
      margin-top: $top-nav-height;
    }
  }
  &.page-manager-visible {
    body { padding-left: $page-manager-width; }
    .site-background{
      margin-left: $page-manager-width;
    }
  }

}
