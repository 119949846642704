// trigger = 'modal-trigger'
// container = 'modal-container'
// close = 'modal-close'
// stateOff = 'is-off'
// stateOn = 'is-on'

$modal-container-padding: 45px;
$modal-overlay-background-color: rgba(0,0,0,.85);
$modal-close-color: #FFF;
$modal-max-width: 800px;
.user_mode {
  .modal-container{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $modal-container-padding;
    float: none;
    z-index: 9999;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: none;
    .pageElement{
      float: none;
      width: auto;
      z-index: 2;
    }
    &.is-on{
      display: flex;
    }
    &.is-off{
      display: none;
    }
  }

  .modal-close-overlay{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: $modal-overlay-background-color;
  }
  .modal-close-button{
    display: none;
    text-align: right;
    z-index: 3;
    width: 100%;
    max-width: $modal-max-width;
    height: 0;
    span{
      display: inline-block;
      &:before{
        content:"\f00d";
        font-family: 'fontawesome';
        color: $modal-close-color;
        font-size: 18px;
        padding: 5px;
        line-height: 1;
        display: inline-block;

        width: 18px;
        height: 18px;
        text-align: center;
      }
    }
  }
  &.custom-modal-open{
    overflow: hidden;
  }

  // Content
  .modal-container{
    .pageElement{
      // padding: 15px;
      // background-color: #FFF;
      margin: 0;
      width: 100%;
      max-width: $modal-max-width;
    }
  }
  // Custom styles
  .modal-container{
    .pageElement{
      // background-image: url(https://cdn3.sportngin.com/attachments/background_graphic/7009/4901/splash-background.jpg);
      // background-size: cover;
      // color: #FFF;
      // h3{text-align: center;}
    }
  }
}


.edit_mode {
  .snFooterContainer .modal-container,
  .modal-close-overlay,
  .modal-close-button{ display: none; }

}



.modal-container{
  .mailSubscribeElement{
    #errorExplanation{
      margin: 0;
      width: 100%;
    }
    border: none;
    display: flex;
    flex-flow: row-reverse;
    justify-content: center;
    align-items: stretch;
    background: #FFF;
    padding: 0;
    > h3{
      color: $primary-green;
      display: flex;
      flex-flow:column;
      justify-content: center;
      padding: 40px;
      // max-width: 520px;
      width: 100%;
      background-image: url(../images/modal-blue.jpg);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      font-size: 24px;
      font-family: $fontOne;
      font-weight: 800;
      &:before{
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 42%;
        background-image: url(../images/deturflogo.png); // modal_site_logo.svg);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
      span{
        margin-top: 20px;
        &:after{
          content: 'Join our newsletter to get special offers, event notifications, and the latest news from DE Turf.';
          display: block;
          color: #FFF;
          margin-top: 10px;
          text-transform: none;
          max-width: 500px;
          font-size: 18px;
          font-family: $fontTwo;
          font-weight: 400;
        }

      }
    }
    form{
      min-width: 270px;
      padding: 40px;
      background-image: url(../images/modal-green.jpg);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
    }
    .resultsTable{
      display: block;
      tbody, tr,th, td{ display: block;}
      td{
        // min-width: 220px;
        padding: 0;
        margin-bottom: 15px;
      }
      th{
        color:$primary-blue;
        @include font-smoothing;
        text-align: left;
        // display:none;
      }
      input{
        height: 44px;
        color: $grey-dk;
        font-size: 14px;
        font-weight:400;
      }
      tr:last-of-type{
        td{text-align: center;margin-bottom: 0;}
      }
      .buttonLink{
        display: inline-block;
        text-align: center;
        padding: 10px 15px;
        background-color: $primary-blue;
        color: #FFF;
        margin: 0 auto;
        text-transform: uppercase;
      }
    }
  }
}
@media screen and (max-width: 620px){
  .modal-container{
    .mailSubscribeElement{
      overflow-y: scroll;
      max-width: 400px;
      // flex-flow: column;
      display: block;
      > h3{
        max-width: 100%;
      }
      form{ width: 100%; min-width: auto;}
    }
  }
}
